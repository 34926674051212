<template>
    <NuxtPage />
</template>

<script setup lang="ts">

const { showRadioList } = usePlayerState()

showRadioList.value = false

</script>

<style></style>